import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import { map } from "rxjs/operators";
import {HttpClient} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

      url: string = 'http://cmefi-api.showmywork.in/';
//      url = 'http://secureapi.cmefi.in';
      // url = 'http://cmefi-api.com/';
    constructor(public http: HttpClient) {}

    login(obj) {
        return this.http.post(this.url + "login", obj).map((res => res));

    }
}

