import {Injectable} from '@angular/core';
import { Router, CanActivate, CanDeactivate } from '@angular/router';
import {Observable} from 'rxjs';


export interface CanRouteDeactivate {
   canDeactivate(nextState: string): Observable<boolean> | Promise<boolean> | boolean;
}


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanDeactivate<CanRouteDeactivate> {

    constructor(private router: Router) {}

    canActivate() {
        if (!sessionStorage.getItem('token')) {
            sessionStorage.clear();
            this.router.navigate(['/']);
            return false;
        }
        else {
            return true;
        }
    }

      canDeactivate(component: CanRouteDeactivate) {
            if (!sessionStorage.getItem('token')) {
            console.log('no, you wont navigate anywhere');
            return false;
        }
        console.log('you are going away, goodby');
        return true;
    // return component.canDeactivate ? component.canDeactivate() : true;
  }

//    canDeactivate() {
//         console.log('i am navigating away');
//         if (!sessionStorage.getItem('token')) {
//             console.log('no, you wont navigate anywhere');
//             return false;
//         }
//         console.log('you are going away, goodby');
//         return true;
//     }

//    canDeactivate() {
//          console.log('if');
//        console.log('2424');
//        if (sessionStorage.getItem('token')) {
//          this.router.navigate(['/dashboard']);
//            return false;
//        }
//        else {
//            return true;
//        }
//    }


}
