import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	username: string;
	constructor(private router: Router) { }

	ngOnInit() {
		this.username = sessionStorage.getItem('username');
		(function () {
			"use strict";

			var slideMenu = $('.side-menu');

			// Toggle Sidebar
			$('[data-toggle="sidebar"]').click(function (event) {
				event.preventDefault();
				$('.app').toggleClass('sidenav-toggled');
			});

			if ($(window).width() > 739) {
				$('.app-sidebar').hover(function (event) {
					event.preventDefault();
					$('.app').removeClass('sidenav-toggled');
				});
			}

			// Activate sidebar slide toggle
			$("[data-toggle='slide']").click(function (event) {
				event.preventDefault();
				if (!$(this).parent().hasClass('is-expanded')) {
					slideMenu.find("[data-toggle='slide']").parent().removeClass('is-expanded');
				}
				$(this).parent().toggleClass('is-expanded');
			});

			// Set initial active toggle
			$("[data-toggle='slide.'].is-expanded").parent().toggleClass('is-expanded');

			//Activate bootstrip tooltips
			$("[data-toggle='tooltip']").tooltip();

		})();

	}


	logout() {
		// this.http.get(this.url + "logout").map((res => res)).subscribe((res: any) => {
		sessionStorage.clear();
		this.router.navigateByUrl('');
		// });
	}

}
