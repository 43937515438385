import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from './login.service';
import {NgForm} from '@angular/forms';
declare var $: any;
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

    constructor(private route: ActivatedRoute, private router: Router, private loginService: LoginService) {}
    user: any = {username: "", password: ""};
    error_msg: string;
    user_type: string;
    username: string;

    ngOnInit() {
        
    }
    onloginSubmit(loginData: NgForm) {
        if (loginData.valid) {
            this.loginService.login(this.user).subscribe((res: any) => {
                //                console.log(res);
                if (res.result == "success") {
                    sessionStorage.setItem("token", res.token);
                    //                    sessionStorage.setItem("user_type", res.user_type);
                    this.user_type = res.user_type;
                    // console.log(this.user_type);
                    var tokenData = res.token;
                    var base64Url = tokenData.split('.')[1];
                    var base64 = base64Url.replace('-', '+').replace('_', '/');
                    var token = JSON.parse(window.atob(base64));
                    sessionStorage.setItem("user_type", token.user.user_type);
                    sessionStorage.setItem("username", token.user.username);
                    // console.log(token);
                    this.router.navigate(['/dashboard'])
                    setTimeout(() => {
                        (function () {
                            $(function () {
                                return $.growl.notice({
                                    message: "You are successfully logged in",
                                    title: "Success"
                                });
                            });
                        }).call(this);
                    }, 1000);
                }

                else {
                    this.error_msg = res.message;
                }
            });
        }
    }
}