import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-leftmenu',
    templateUrl: './leftmenu.component.html',
    styleUrls: ['./leftmenu.component.css']
})
export class LeftmenuComponent implements OnInit {

    user: string;
    user_type: any;
    username: string;

    constructor() {}

    ngOnInit() {
        this.user_type = sessionStorage.getItem('user_type');
        this.username = sessionStorage.getItem('username');
        if (this.user_type == 1) {
            this.user = "Admin";
        }
        else if (this.user_type == 2) {
            this.user = "Manager";
        }
        else if (this.user_type == 3) {
            this.user = "RBM";
        }
        else if (this.user_type == 4) {
            this.user = "Travel";
        }
        else if (this.user_type == 5) {
            this.user = "ZBM";
        }
        else if (this.user_type == 6) {
            this.user = "NBM";
        }
        else {
            this.user = "DataEntry";
        }
    }
}
