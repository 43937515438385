import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainlayoutComponent } from './layout/mainlayout/mainlayout.component';
import { LoginComponent } from './shared/login/login.component';
import { AuthGuard } from './auth.guard';
const routes: Routes = [

    {
        path: '',
        canDeactivate: [AuthGuard],
        component: LoginComponent
    },
    {
        path: '',
        component: MainlayoutComponent,
        loadChildren: './shared/shared.module#SharedModule',
         canActivate: [AuthGuard]
    }

];
// let user_type = sessionStorage.getItem('user_type');
// if (user_type) {
//     routes[1].path = "";
// }

// console.log(routes[1]);

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
