import {Injectable, Injector} from '@angular/core';
import {Router} from "@angular/router";
import {
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';


declare var $: any;
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private injector: Injector, private router: Router) {

    }

    private applyCredentials = function (req) {

        var req_token = ""
        if (sessionStorage.getItem('token'))
            req_token = sessionStorage.getItem('token')
        return req.clone({
            headers: req.headers.set('Authorization', req_token || "")
        });
    };
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.applyCredentials(req))
            .do((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {

                }
            })
            .catch((err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        if (err.error.unauthorized) {
                            return;
                        }
                        sessionStorage.removeItem('token');
                        sessionStorage.setItem('token', err.error.refresh_token);
                        //   this.clearSessionInterval();
                        return next.handle(this.applyCredentials(req));
                    }

                }
            });
    }




}